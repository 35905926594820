*{
  margin:0;
  padding:0;
  box-sizing: border-box;
  font-family: 'Space Grotesk' !important;
  overflow-x: hidden;
  -ms-overflow-style: none;  
  scrollbar-width: none; 

}


*::-webkit-scrollbar  {
  display: none;
}


