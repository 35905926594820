
.custom-switch {

  
    .MuiSwitch-switchBase {

      color: #BEBEBE;
      
  
      &.Mui-checked {

        color: #51C7EF;
  
        + .MuiSwitch-track {
          opacity: 1;
          background-color: #003470;
        }
      }
    }
  
    .MuiSwitch-thumb {
      box-shadow: none;
    
    }
  
    .MuiSwitch-track {
      
      border-radius: 24px;
      opacity: 1;
      background-color: #003470;
    }
  }


  
.custom-switch-dark {

  
    .MuiSwitch-switchBase {

      color: #BEBEBE;
      
  
      &.Mui-checked {

        color: #51C7EF;
  
        + .MuiSwitch-track {
          opacity: 1;
          background-color: white;
        }
      }
    }
  
    .MuiSwitch-thumb {
      box-shadow: none;
    
    }
  
    .MuiSwitch-track {
      
      border-radius: 24px;
      opacity: 1;
      background-color: white;
    }
  }

  .switch {
    display: block;
    --width-of-switch: 3.5em;
    --height-of-switch: 2em;
    /* size of sliding icon -- sun and moon */
    --size-of-icon: 1.4em;
    /* it is like a inline-padding of switch */
    --slider-offset: 0.3em;
    position: relative;
    width: var(--width-of-switch);
    height: var(--height-of-switch);
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f4f4f5;
    transition: .4s;
    border-radius: 30px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: var(--size-of-icon,1.4em);
    width: var(--size-of-icon,1.4em);
    border-radius: 20px;
    left: var(--slider-offset,0.3em);
    top: 50%;
    transform: translateY(-50%);
    background: linear-gradient(40deg,#ff0080,#ff8c00 70%);
    ;
   transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #303136;
  }
  
  input:checked + .slider:before {
    left: calc(100% - (var(--size-of-icon,1.4em) + var(--slider-offset,0.3em)));
    background: #303136;
    /* change the value of second inset in box-shadow to change the angle and direction of the moon  */
    box-shadow: inset -3px -2px 5px -2px #8983f7, inset -10px -4px 0 0 #a3dafb;
  }
  
  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }


  .stm {
    background: #0b338f !important;
    color: #ffffff !important;
    border-radius: 40px !important;
    cursor: pointer;
    font-weight: bold;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  
  .stm:hover{
    background: #0c43b6 !important;
  }


  

  .sidebarelement{
    display: 'block';
}

.sidebarelement:hover,
.sidebarelement:active {
  background-color: #0E1C5E;
  border-radius: 5%;
  transition: 'border-radius' 0.3s;
  color: white;
}

