@font-face {
    font-family: 'Space Grotesk';
    src: url('./fonts/static/SpaceGrotesk-Regular.ttf') format('truetype'); /* Adjust the file path as needed */
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Space Grotesk';
    src: url('./fonts/static/SpaceGrotesk-Bold.ttf') format('truetype'); /* Adjust the file path as needed */
    font-weight: 700;
    font-style: normal;
  }