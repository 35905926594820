.center{
    width: 100vw;
    height: 100vh;
    background-color: #060b28 ;
    display: flex;
    justify-content: center;
    align-items: center;

}

.ring{
    position: absolute;
    height: 200px;
    width: 200px;
    border-radius: 50%;
    animation: ring 2s linear infinite;
}

@keyframes ring {
    0%{
        transform: rotate(0deg);
        box-shadow: 1px 5px 2px #e65c00;
    }
    50%{
        transform: rotate(180deg);
        box-shadow: 1px 5px 2px #18b201;
    }
    100%{
        transform: rotate(360deg);
        box-shadow: 1px 5px 2px #0456c8;
    }
}

.ring:before{
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    left: 0;
    top: 0;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(255,255,255,.3);

}

.span{
    color: #737373;
    font-size: 20px;
    text-transform: uppercase;
    line-height: 200px;
    letter-spacing: 1px;
    animation: text 3s ease-in-out infinite;
}

@keyframes text {
    50%{
        color: black;
    }
}